/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../../constants/common-constants';
import _ from 'lodash';
import { useParams } from 'react-router';

import styles from './CharacteristicsAnalyzer.module.scss';
import '../AnalyzerComponents.scss';

import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaTable from '../../../bitaComponents/BitaTable/BitaTable';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import IndexCharacteristicsConstituents from './tables/IndexCharacteristicsConstituents';

import {
  gicsWeightingTheme,
  countryWeightingTheme,
} from '../../../bitaComponents/BitaCharts/Themes';
import ConfigYAML from '../../../config/ConfigYAML';
import { Placeholders } from '../../../constants/placeholders.js';
import { IconsModules } from '../../../constants/icons-module';
import { excludeUser } from '../../../constants/exclude-modules-user';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import * as Action from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';
import ModalBasketCalculation from '../ModalBasketCalculation/ModalBasketCalculation';
import {
  setDecimal,
  thousandFormat,
  hasValidBitaDomain,
  versionIndexName,
} from '../../../utils/utils';
import { getIndexPCFInfo } from '../../../state/actions/benchmark-actions';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';
import { getIndexBenchmark } from '../../../state/actions/benchmark-actions';

const roundUpPercentageString = number => {
  return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;
};

const CharacteristicsAnalyzer = ({ benchmarkList }) => {
  const [dataTable, setDataTable] = useState();
  const [indexStats, setIndexStats] = useState(null);
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [gicsData, setGicsData] = useState({});
  const userData = useSelector(state => state.auth.userdata);
  const [esgData, setEsgData] = useState({});
  const [searchConstituents, setSearchConstituents] = useState('');
  const [countryWeightData, setCountryWeightData] = useState({});
  const [dateSelected, setDateSelected] = useState('');
  const [pcfInfo, setPcfInfo] = useState([]);
  const [optionCharacteristics, setOptionCharacteristics] = useState([]);
  const [benchmarkStats, setBenchmarkStats] = useState();
  const [datesArray, setDateArray] = useState([]);
  const apiData = useSelector(state => state.apiData);
  const company = useSelector(state => state.auth.company);
  const [riskModel, setRiskModel] = useState(11);
  const [dateLabel, setDateLabel] = useState('');
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const modalRef = useRef();
  const ModalRRRef = useRef();
  const ModalBCRef = useRef();
  const backtestData = useSelector(state => state.backtest);
  const [indexBenchmark, setIndexBenchmark] = useState();
  const token = useSelector(state => state.auth.token);
  const { indexId } = useParams();

  const isShowActivateCalculation =
    hasValidBitaDomain(userData?.email) || userData?.company?.name === 'demo_company';

  const isRegularOrRebalancingIndex =
    currentIndexSelected?.data?.index_type === 'regular' ||
    currentIndexSelected?.data?.index_type === 'rebalancing_module';

  useEffect(() => {
    if (dateSelected !== '' && currentIndexSelected.data) {
      getIndexPCFInfo(currentIndexSelected.data.id, dateSelected, token).then(resp => {
        if (resp.data && resp.data.data.pcf_info) {
          setPcfInfo(resp.data.data.pcf_info);
        }
      });
    }
  }, [dateSelected]);

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      setIndexStats(apiData.apiData['Index Stats']);
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Benchmark Card Stats']) {
      setBenchmarkStats(apiData.apiData['Benchmark Card Stats']);
    }
  }, [apiData.apiData['Benchmark Card Stats']]);

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      setIndexBenchmark(currentIndexSelected.data.benchmark_id);
      const usedBenchmark = benchmarkList.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionCharacteristics(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    if (currentIndexSelected?.data?.id && currentIndexSelected?.data?.benchmark_id) {
      getIndexBenchmark(
        currentIndexSelected.data.id,
        currentIndexSelected.data.benchmark_id,
        token,
      ).then(resp => {
        dispatch(
          Action.setApiData({
            title: 'Benchmark Card Stats',
            data: resp.data,
          }),
        );
      });
    }
  }, [indexBenchmark]);

  useEffect(() => {
    dispatch(Action.setIndexStats(token, currentIndexSelected?.data?.id || indexId));
  }, [currentIndexSelected]);
  useEffect(() => {
    setDateSelected(datesArray[0]?.date || '');
    setDateLabel(datesArray[0]?.value || '');
  }, [datesArray]);

  useEffect(() => {
    const dateList = _.get(currentIndexSelected, 'data.dates_constitutions_real', {});
    if (dateList) {
      setDateArray(
        Object.entries(dateList)
          .slice()
          .reverse()
          .map(([key, date], i) => {
            return { id: i, value: key, date: date };
          }),
      );
    }
  }, [currentIndexSelected]);

  useEffect(() => {
    if (dateSelected) {
      const dataESGScores = {
        index: _.get(indexStats, 'data[97].value')?.find(c => c.timestamp.includes(dateSelected)),
        benchmark: _.get(benchmarkStats, 'data[97].value')?.find(c =>
          c.timestamp.includes(dateSelected),
        ),
      };

      setEsgData(dataESGScores);

      const dataGics = _.get(indexStats, 'data[55].value')?.find(c =>
        c.timestamp.includes(dateSelected),
      );
      const sortedGicsValues = _.chain(dataGics?.value)
        .toPairs()
        .orderBy(1, 'desc')
        .fromPairs()
        .value();

      const sortedGics = { ...dataGics, value: sortedGicsValues };
      let shortDataGICS = Object.entries(sortedGics.value).map(([key, v]) => ({
        [key]: v,
      }));

      let showshortDataGICS = Object.entries(sortedGics.value)
        .filter(([k, v]) => v !== 0)
        .map(([key, v]) => ({
          [key]: v,
        }));

      if (shortDataGICS.length > 5 || shortDataGICS.length > showshortDataGICS.length) {
        const limitValue = showshortDataGICS.length < 5 ? showshortDataGICS.length : 5;
        const firstValues = shortDataGICS
          .slice(0, limitValue)
          .reduce((acc, curr) => ({ ...acc, [Object.keys(curr)[0]]: Object.values(curr)[0] }));
        const otherValues = shortDataGICS
          .slice(limitValue)
          .reduce((acc, curr) => ({ Other: Object.values(curr)[0] + Object.values(acc)[0] }));
        setGicsData({ ...sortedGics, value: { ...firstValues, ...otherValues } });
      } else {
        setGicsData(sortedGics);
      }

      const dataCountryWeight = _.get(indexStats, 'data[56].value')?.find(c =>
        c.timestamp.includes(dateSelected),
      );

      const sortedCountryWeightValues = _.chain(dataCountryWeight?.value)
        .toPairs()
        .orderBy(1, 'desc')
        .fromPairs()
        .value();

      const sortedCountryWeight = { ...dataCountryWeight, value: sortedCountryWeightValues };
      let shortDataCountry = Object.entries(sortedCountryWeight.value).map(([key, v]) => ({
        [key]: v,
      }));
      // UN array con los que no tienen valor 0
      let showshortDataCountry = Object.entries(sortedCountryWeight.value)
        .filter(([k, v]) => v !== 0)
        .map(([key, v]) => ({
          [key]: v,
        }));

      if (shortDataCountry.length > 5 || shortDataCountry.length > showshortDataCountry.length) {
        const limitValue = showshortDataCountry.length < 5 ? showshortDataCountry.length : 5;
        const firstValues = shortDataCountry
          .slice(0, limitValue)
          .reduce((acc, curr) => ({ ...acc, [Object.keys(curr)[0]]: Object.values(curr)[0] }));
        const otherValues = shortDataCountry
          .slice(limitValue)
          .reduce((acc, curr) => ({ Other: Object.values(curr)[0] + Object.values(acc)[0] }));
        setCountryWeightData({ ...sortedCountryWeight, value: { ...firstValues, ...otherValues } });
      } else {
        setCountryWeightData(sortedCountryWeight);
      }
    }
  }, [indexStats, dateSelected]);

  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });

  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats !== null) {
      if (typeof indexStats === 'object' && indexStats.length !== 0) {
        setTimeout(() => {
          setPseudoTimeout(true);
        }, timer);
      }
    }
  }, [indexStats]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        Action.setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        Action.setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        Action.setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionCharacteristics,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      Action.setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionCharacteristics,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: CommonActions.SUBHEADER_UPDATE,
      payload: 'ANALYZER · CHARACTERISTICS',
    });
  }, [dispatch]);

  const baseInfo = ConfigYAML.jsonPath([
    'analyzer',
    "sections[?(@.name == 'Characteristics')]",
    "widgets[?(@.title == 'Universal Global')]",
    'components',
  ]);

  const generalStats = ConfigYAML.jsonPath([
    'analyzer',
    "sections[?(@.name == 'Characteristics')]",
    "widgets[?(@.title == 'Index Characteristics')]",
    'components',
  ]);

  const chartData = ConfigYAML.jsonPath([
    'analyzer',
    "sections[?(@.name == 'Characteristics')]",
    'widgets',
    "components[?(@.type == 'HorizontalBarChart')]",
  ]);
  const chartData2 = ConfigYAML.jsonPath([
    'analyzer',
    "sections[?(@.name == 'Characteristics')]",
    'widgets',
    "components[?(@.title == 'Country Weighting')]",
  ]);

  const tableData = ConfigYAML.jsonPath([
    'analyzer',
    "sections[?(@.name == 'Characteristics')]",
    "widgets[?(@.title == 'Constituents')]",
    'components',
  ]);

  const dropdowntimeData = ConfigYAML.jsonPath([
    'analyzer',
    "sections[?(@.name == 'Characteristics')]",
    'widgets',
  ]);

  const arraytime = [];

  let optionsTime = Object.values(dropdowntimeData[0].components[0].options);

  optionsTime.map(time => {
    let item = { id: time, value: time };
    arraytime.push(item);
  });

  const numberWithCommas = n => {
    const auxFloat = n.indexOf('.');
    const aux = n.replace(/M/g, '').replace(/,/g, '');
    const parts = aux.toString().split('.');
    return (
      parts[0].replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      (parts[1] ? `.${parts[1].replace(/[^0-9]/g, '')}` : auxFloat > 0 ? '.' : '')
    );
  };

  // eslint-disable-next-line consistent-return
  const abbreviateNumber = auxValue => {
    const n = auxValue;
    if (n < 1e6) return n;
    if (n >= 1e6) return `${numberWithCommas((n / 1e6).toFixed(2))}M`;
  };

  const generateHeaders = () => {
    const headers = ['Name', 'Price', 'Weight', 'Market Capitalization'];
    return headers.map(value => <th className={styles.tableHeader}>{value}</th>);
  };

  const getIsinWeight = weightType => {
    return weightType.indexOf('adjusted_weight') > -1
      ? weightType.indexOf('adjusted_weight')
      : weightType.indexOf('uncapped_weight');
  };

  const compareWeight = (first, second) => {
    if (parseFloat(first.Weight) < parseFloat(second.Weight)) return 1;
    if (parseFloat(first.Weight) > parseFloat(second.Weight)) return -1;
    return 0;
  };

  const generateRows = date => {
    if (Object.keys(currentIndexSelected).length > 0 && date !== '') {
      const auxformattedData = [];
      const constituents = _.get(pcfInfo, `constituents`, {});
      const assets = _.get(currentIndexSelected, `data.assets_descriptors`, {});
      if (Object.keys(constituents).length > 0) {
        const rows = Object.keys(constituents);
        const formattedData = constituents?.index?.map((ISIN, index) => ({
          Name: assets[ISIN]?.name || '',
          Price: setDecimal(constituents.data[index][constituents.columns.indexOf('price')]),
          Weight: `${roundUpPercentageString(
            constituents.data[index][getIsinWeight(constituents.columns)] * 100,
          )} %`,
          'Market Capitalization': thousandFormat(
            setDecimal(
              constituents.data[index][
                constituents.columns.indexOf('Market Capitalization - Instrument')
              ],
            ),
          ),
        }));

        formattedData?.map(row => {
          if (searchConstituents !== '' && searchConstituents !== undefined) {
            if (row.Name.toUpperCase().lastIndexOf(searchConstituents.toUpperCase()) > -1) {
              auxformattedData.push(row);
            }
          } else {
            auxformattedData.push(row);
          }
        });
        setDataTable(auxformattedData.sort(compareWeight));
      }
    }
  };

  const tableGenerator = () => (
    <div className={`${styles.tableContainerGenerated} tableCharacteristics`}>
      {dataTable ? (
        <BitaTable
          data={dataTable}
          showFields={['Name', 'Price', 'Weight', 'Market Capitalization']}
          search={false}
          showOnly={10}
        />
      ) : (
        ''
      )}
    </div>
  );
  React.useEffect(() => {
    generateRows(dateSelected);
  }, [pcfInfo, searchConstituents]);

  const listData = _.get(indexStats, 'data', []);

  const generateTables = () =>
    generalStats.map(
      stat =>
        stat.type === 'Table' && (
          <div className={styles.tableContainer}>
            <table className={styles.generatedTable}>
              <thead className={styles.generatedTableHeader}>
                <th className={styles.firstTableHeader}>{stat.title}</th>
                {Object.entries(stat.data).map(([, value]) => (
                  <th className={styles.restTableHeaders}>{value.column_name}</th>
                ))}
              </thead>
              {Object.entries(stat.rows_names).map(([, value], index) => (
                <tr className={styles.generatedTableValues}>
                  <td className={styles.firstTableValue}>{value.value} </td>
                  {esgData &&
                    Object.entries(esgData).map(([col, valueD]) => {
                      let scoreValue =
                        esgData[col] &&
                        (valueD.value[`${value.key}`] || valueD.value[`${value.key}`] === 0)
                          ? setDecimal(valueD.value[`${value.key}`])
                          : 'N/A';
                      return <td className={styles.restTableValues}>{scoreValue}</td>;
                    })}
                </tr>
              ))}
            </table>
          </div>
        ),
    );
  const generateList = data =>
    generalStats.map(
      stat =>
        stat.type === 'List' && (
          <div className={styles.listContainer}>
            {Object.keys(stat.data).map(key => (
              <ul className={styles.listContent}>
                <li className={styles.listTitleContainer}>
                  <span className={styles.listTitle}>
                    {stat.data[key].title.replace(
                      'index_currency',
                      currentIndexSelected?.data?.currency,
                    )}{' '}
                  </span>
                </li>
                {Object.entries(stat.data[key].values).map(([title, value]) => (
                  <li className={styles.listValue}>
                    {value.key && <span className={styles.rightText}>{value.key}</span>}
                    <span className={styles.listValueText}>
                      {dateSelected &&
                        data[value?.value]?.value &&
                        roundUpPercentageString(
                          data[value.value].value.find(c => c.timestamp.includes(dateSelected))
                            ?.value || 'N/A',
                        ) &&
                        abbreviateNumber(
                          roundUpPercentageString(
                            data[value.value].value.find(c => c.timestamp.includes(dateSelected))
                              ?.value || 'N/A',
                          ),
                        )}
                    </span>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        ),
    );

  const gicsWeightOptions = {
    title: {
      text: `Sector Weighting (${currentIndexSelected?.data?.sector_provider || 'GICS'})`,
    },
    xAxis: [
      {
        labels: {
          useHTML: true, //Set to true
          style: {
            color: '#FFF',
            fontSize: '10px',
            fontWeight: '800',
            width: '100px',
            whiteSpace: 'normal', //set to normal
          },
          padding: 5,
        },
        opposite: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        categories:
          gicsData.value &&
          Object.entries(gicsData.value).map(
            ([key, value]) => `${key} ( ${roundUpPercentageString(value * 100)} %) `,
          ),
      },
    ],
    series: [
      {
        name: '',
        data: gicsData.value && Object.values(gicsData.value),
      },
    ],
  };

  const countryWeightOptions = {
    xAxis: [
      {
        labels: {
          useHTML: true, //Set to true

          style: {
            color: '#FFF',
            fontSize: '10px',
            fontWeight: '800',
            width: '100px',
            whiteSpace: 'normal', //set to normal
          },
        },
        opposite: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        categories:
          countryWeightData.value &&
          Object.entries(countryWeightData.value).map(
            ([key, value]) => `${key} ( ${roundUpPercentageString(value * 100)} %) `,
          ),
      },
    ],
    series: [
      {
        name: '',
        data: countryWeightData.value && Object.values(countryWeightData.value),
      },
    ],
    // Object.values(chartData2.data).map(v => parseFloat(v.value)) }],
  };

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <ModalBasketCalculation ubication="analyzer" showData ref={ModalBCRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} disable_title />
      <DownloadCenterModal />
      <div className={styles.contentContainer}>
        <div className={styles.dateContainer}>
          <div>{dropdowntimeData[0].components[0].text}</div>
          <div style={{ marginTop: '5px', marginLeft: '10px' }}>
            <BitaSelect
              data={datesArray}
              handleSelection={selected => {
                setDateSelected(selected.date);
                setDateLabel(selected.value);
              }}
              label={dateLabel}
            />
          </div>
        </div>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer}>
            <div className={styles.baseInfoContainer}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  {' '}
                  <ImageI /> {currentIndexSelected.data?.name}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date?.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : 'auto',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={benchmarkList}
                        onChange={selected => {
                          setOptionCharacteristics(selected);
                        }}
                        value={optionCharacteristics?.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.downloadContainer}>
              <span className={styles.downloadTitle}>Download Center</span>
              {(currentIndexSelected?.data?.index_type === 'rebalancing_module' ||
                filesDownloadCenter.status === 'done' ||
                filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      machine_readable_constituents_summary_url:
                        currentIndexSelected?.data?.machine_readable_constituents_summary_url,
                      machine_readable_basket_management_constituents_url:
                        currentIndexSelected?.data
                          ?.machine_readable_basket_management_constituents_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div className={styles.generalStatsContainer}>
            <div className={styles.generalTitleContainer}>
              <span className={styles.generalTitle} style={{ whiteSpace: 'nowrap' }}>
                {currentIndexSelected?.data?.index_type === 'rebalancing_module' ? '' : 'Index'}{' '}
                Characteristics
              </span>
              {isShowActivateCalculation && !isRegularOrRebalancingIndex && (
                <div
                  style={{
                    position: 'relative',
                    top: '-150px',
                    left: '0px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    style={{
                      whiteSpace: 'nowrap',
                      width: '200px',
                      marginRight: '10px',
                      padding: '5px',
                      display: excludeUser[userData?.company?.name] ? 'none' : '',
                    }}
                    onClick={() => {
                      ModalBCRef.current.runModal();
                    }}
                  >
                    Activate in Calculation
                  </BitaButton>
                </div>
              )}
              {isRegularOrRebalancingIndex && (
                <div
                  style={{
                    position: 'relative',
                    top: '-150px',
                    left: isShowActivateCalculation ? '-230px' : '0px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {isShowActivateCalculation && (
                    <BitaButton
                      primaryWhite
                      style={{
                        whiteSpace: 'nowrap',
                        width: '200px',
                        marginRight: '10px',
                        padding: '5px',
                        display: excludeUser[userData?.company?.name] ? 'none' : '',
                      }}
                      onClick={() => {
                        ModalBCRef.current.runModal();
                      }}
                    >
                      Activate in Calculation
                    </BitaButton>
                  )}
                  <BitaButton
                    primaryWhite
                    style={{ width: '200px', marginRight: '10px' }}
                    onClick={activeRebalanceReconstitution}
                  >
                    Rebalance Portfolio/Index
                  </BitaButton>
                  {currentIndexSelected?.data?.index_type !== 'rebalancing_module' && (
                    <BitaButton primaryWhite style={{ width: '150px' }} onClick={activeRunBackTest}>
                      Update Index
                    </BitaButton>
                  )}
                </div>
              )}
            </div>
            <div className={styles.tablesContainer}>
              <div className={styles.generalCharts}>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  {<IndexCharacteristicsConstituents pcfInfo={pcfInfo} />}
                  {listData && generateList(listData)}
                </div>
                {generateTables()}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomHalfContainer}>
          <div className={styles.constituentsContainer}>
            <div className={styles.constituentsTitleContainer}>
              <span className={styles.constituentsTitle}>
                Top Constituents{' '}
                {dataTable ? dataTable?.length > 10 && '10 of ' + dataTable?.length : ''}
              </span>
              <div className={styles.constituentsSearchContainer}>
                <input
                  className={`${styles.searchInput} searchInput`}
                  type="text"
                  value={searchConstituents}
                  onChange={val => setSearchConstituents(val.target.value)}
                  autoFocus
                  placeholder="Search by..."
                />
              </div>
            </div>
            <div className={styles.constituentsTableContainer}>{tableGenerator()}</div>
          </div>
          <div className={styles.sectorAndWeightsContainer}>
            <span className={styles.sectorTitle}>Top Sector and Country Weighting</span>
            <div className={`${styles.weightsChartContainer} ChartContainer `}>
              <BitaCharts options={gicsWeightOptions} theme={gicsWeightingTheme} />
            </div>
            <div className={`${styles.sectorsChartContainer} ChartContainer `}>
              <BitaCharts
                options={countryWeightOptions}
                theme={countryWeightingTheme}
                containerProps={{ style: { width: '100%' } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacteristicsAnalyzer;
